.auth-frame-container {
	min-width: 480px;
}

.auth-sidebar {
	background-image: url("./StackedWaves.svg");
	background-size: cover;
	/*background-position: center;*/
	background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
	.auth-frame-container {
		min-width: 0;
	}
}
