.ant-calendar {
	width: 30rem; 
	margin-top: 3rem;
  padding: -2rem;
    border: 1px solid black;
    transition-delay: 1500ms;
  }
.book-now button {
    border-radius: 4px;
    border-bottom: 3px solid rgb(21, 74, 159);
    background-color: white;
    display: flex;
    flex-direction: column;
    /* border-color: rgb(59 130 246 / .5);; */
    font-weight: 800;
    /* flex-wrap: wrap; */
    color: white;
    background-color: rgb(39, 105, 212);
    text-align: center;
    justify-content: center;
    
    /* margin: 0.5rem; */
    margin: auto;
    padding: auto;
    height: 3rem;
    width: 16rem;
    padding-left: 6rem;
    margin-top: 2rem;
}
  .time-slots button{
    padding: 1rem 4.35rem;
    border-radius: 8px;
    border: 1.5px solid rgb(39, 105, 212);
    background-color: white;
    display: flex;
    flex-direction: column;
    /* border-color: rgb(59 130 246 / .5);; */
    font-weight: 500;
    /* flex-wrap: wrap; */
    color: rgb(39, 105, 212);
    text-align: center;
    justify-content: center;
    
    margin: 0.5rem;
    height: 3rem;
    width: 14rem;
    padding-left: 5rem;
}

.time-slots button:first-child{
  display: none;
}

button:focus {
    background-color: rgb(39, 105, 212);
    color: white;
    transition-delay: 15ms;
}

.center{
    display: flex;
    flex-direction: column ;
}

.tiles {
    margin-top: 2rem;
    justify-content: center;
    background-color: white;
    text-align: center;
    height: 350px;
     overflow-x: hidden; 
    overflow-y: auto;
    text-align:center;
}

/* .tiles h4{
    position: absolute;
} */
.app{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/* button:last-child {
    background-color: white;
    border: none;
  } */

  .text{
    text-align: center;
  }

  .calendar-transition {
    transition: transform 1s ease-in-out;
  }
  